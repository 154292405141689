import React, { Component } from 'react'
import TextField from '@cimpress/react-components/lib/TextField'
import autobind from 'react-autobind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { search } from '../authentication/sapi'
import { sort } from '../authentication/sortman'
import { palletBoxes } from '../authentication/palletman'
import { scanEvent } from '../authentication/ses'
import { dispatchMessage } from '../redux/notifications/actions'
import { logPackageScan } from '../logging/logger'
import CODE_LOCATIONS from '../logging/codeLocation'

export class Package extends Component {
  constructor(props) {
    super(props)
    this.state = { package: '' }
    autobind(this)
  }

  onScanBoxChange({ target: { value } }) {
    if (!this.props.currentLocation.settings?.leaveScannedWhitespace)
      value = value.trim()
    this.setState({ package: value })
  }

  getElapsedTimeInMs(startTime) {
    const endTime = performance.now()
    return (endTime - startTime).toFixed(2)
  }

  onKey({ key }) {
    if (key !== 'Enter') return
    this.onPackage(this.state.package)
  }

  isPackageSorted(shipment) {
    return !!shipment.palletBoxId
  }

  async getShipmentAndSortLocation(value) {
    let sortLocation, startTime, elapsedTimeInMs

    startTime = performance.now()
    let shipment = await search({
      term: value,
      fulfillmentLocationId: this.props.currentLocation.fulfillmentLocationId
    })
    elapsedTimeInMs = this.getElapsedTimeInMs(startTime)

    if (this.props.match && this.props.match.id !== shipment.id) {
      logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
        error:
          'Attempting to scan packages belonging to different shipments. Please rescan.',
        elapsedTimeInMs
      })
      throw new Error('Please rescan package')
    }

    logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
      message: 'Package search in SAPI succeeded.',
      elapsedTimeInMs
    })
    startTime = performance.now()
    try {
      ;[sortLocation] = await Promise.all([
        sort({ shipment }, this.props.currentLocation.fulfillmentLocationId),
        scanEvent({
          packageId: shipment.packages[0].reference,
          logisticsLocationId: this.props.currentLocation.id,
          user: this.props.profile.name
        })
      ])
    } catch (error) {
      elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
      logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
        error: `Encountered an error retrieving sort location or sending a scan event: ${error}`,
        elapsedTimeInMs
      })
      throw new Error(`Problem encountered: ${error}`)
    }
    elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
    logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
      message: 'Successfully retrieved sort location.',
      elapsedTimeInMs
    })

    return { shipment, sortLocation }
  }

  async onPackage(value) {
    if (!value) return
    try {
      logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
        message: 'Initial package Scan'
      })
      const startTime = performance.now()
      this.props.onScan()
      let [{ shipment, sortLocation }, pallets] = await Promise.all([
        this.getShipmentAndSortLocation(value),
        palletBoxes({
          status: 'open',
          logisticsLocationId: this.props.currentLocation.id
        })
      ])
      const elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
      logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
        message: 'Shipment, sort location and pallet retrieval successful.',
        elapsedTimeInMs
      })

      if (
        this.isPackageSorted(shipment) &&
        !this.props.disablePackageSortedWarning
      ) {
        logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
          message: 'Loading move package screen.'
        })
        this.props.onPackage({
          package: value,
          shipment,
          sortLocation,
          palletBoxes: pallets,
          step: 'move-package'
        })
      } else {
        logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
          message: 'Loading pallet scan screen.'
        })
        dispatchMessage('Scan package successful.', 'success_pkg_scan')
        this.props.onPackage({
          package: value,
          shipment,
          sortLocation,
          palletBoxes: pallets,
          step: 'match-pallet'
        })
      }
    } catch (error) {
      logPackageScan(value, CODE_LOCATIONS.PKG_SCAN, {
        error: `Package scan process encountered an error. ${error}`
      })
      this.setState({ package: '' })
      this.props.onError(error)
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <TextField
              id="text-field"
              name="package"
              label={`Please scan package ${this.props.match ? 'again' : ''}`}
              value={this.state.package}
              onChange={this.onScanBoxChange}
              onKeyDown={this.onKey}
              autoFocus
            />
          </div>
        </div>
      </div>
    )
  }
}

Package.propTypes = {
  match: PropTypes.object,
  onPackage: PropTypes.func,
  onError: PropTypes.func,
  onScan: PropTypes.func,
  currentLocation: PropTypes.object
}

export default connect((state, ownProps) => {
  return {
    currentLocation: state.app.currentLocation,
    disablePackageSortedWarning:
      state.app.currentLocation.settings.disablePackageSortedWarning || false,
    profile: state.webShell.isAuthenticated
      ? state.webShell.profile.userProfile
      : state.auth.profile,
    match: ownProps.match,
    onPackage: ownProps.onPackage,
    onError: ownProps.onError,
    onScan: ownProps.onScan
  }
})(Package)
