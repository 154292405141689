import React, { Component } from 'react'
import TextField from '@cimpress/react-components/lib/TextField'
import autobind from 'react-autobind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { sort } from '../authentication/sortcom'
import { palletBoxes } from '../authentication/palletman'
import { logPalletScan } from '../logging/logger'
import moment from 'moment'
import CODE_LOCATIONS from '../logging/codeLocation'

const CHUTE_MESSAGE_PALLET_0_CHUTE_1 =
  'Go to one of the following chute(s): [VAR_CHUTES] ([VAR_LOCATION]).'
const CHUTE_MESSAGE_PALLET_0_CHUTE_0 =
  'There are no open chutes for sort location [VAR_LOCATION].'
const CHUTE_MESSAGE_PALLET_1_CHUTE_1 =
  'Go to one of the following chute(s): [VAR_CHUTES] or one of the following pallet box(es): [VAR_PALLET_BOXES] ([VAR_LOCATION]).'
const CHUTE_MESSAGE_PALLET_1_CHUTE_0 =
  'Go to one of the following pallet box(es): [VAR_PALLET_BOXES] ([VAR_LOCATION]).'

const MESSAGE_PALLET_0_CHUTE_1 =
  'Please scan pallet box for chute(s) [VAR_CHUTES]'
const MESSAGE_PALLET_0_CHUTE_0 =
  'You may scan a closed pallet box associated with this sort location'
const MESSAGE_PALLET_1_CHUTE_1 =
  'Please scan pallet box for chute(s) [VAR_CHUTES] or scan one of the following pallet box(es): [VAR_PALLET_BOXES]'
const MESSAGE_PALLET_1_CHUTE_0 =
  'Please scan one of the following pallet box(es): [VAR_PALLET_BOXES]'

export class PalletBox extends Component {
  constructor(props) {
    super(props)

    let chuteIds = new Set()
    let palletBoxIdsWithNoChute = new Set()
    props.match.forEach((palletBox) => {
      if (
        palletBox.status === 'open' &&
        palletBox.sortLocation === props.sortLocation
      ) {
        if (palletBox.chuteId) {
          chuteIds.add(palletBox.chuteId)
        } else {
          palletBoxIdsWithNoChute.add(palletBox.palletBoxId)
        }
      }
    })

    this.state = {
      palletBox: '',
      chuteIds: Array.from(chuteIds),
      palletBoxIdsWithNoChute: Array.from(palletBoxIdsWithNoChute)
    }
    autobind(this)
  }

  componentDidMount() {
    if (
      this.state.chuteIds.length === 0 &&
      this.state.palletBoxIdsWithNoChute.length === 0 &&
      !this.props.allowScanningIntoClosedPalletBoxes
    ) {
      this.props.onError({
        message: `No open pallet boxes exist for sort location ${this.props.sortLocation}. Please open a pallet box first.`
      })
    }
  }

  onScanBoxChange({ target: { value } }) {
    this.setState({ palletBox: value.trim() })
  }

  onKey({ key }) {
    if (key !== 'Enter') return

    this.onPalletBox(this.state.palletBox)
  }

  getElapsedTimeInMs(startTime) {
    const endTime = performance.now()
    return endTime - startTime
  }

  async sendSortEvent(
    packageScan,
    packageReference,
    palletBoxId,
    logisticsLocationId
  ) {
    if (this.props.useBarcodeScanForPackageId) {
      await sort({ packageId: packageScan, palletBoxId, logisticsLocationId })
    } else {
      await sort({
        packageId: packageReference,
        palletBoxId,
        logisticsLocationId
      })
    }
  }

  async onPalletBox(scannedPalletBoxId) {
    if (!scannedPalletBoxId) return
    let startTime, elapsedTimeInMs
    logPalletScan(
      scannedPalletBoxId,
      this.props.package,
      CODE_LOCATIONS.PALLET_SCAN,
      {
        message: 'INITIAL PALLET SCAN',
        sortLocation: this.props.sortLocation
      }
    )

    startTime = performance.now()

    try {
      this.props.onScan()

      const {
        shipment: {
          packages: [{ reference: packageId }],
          shippingAddress
        },
        match
      } = this.props
      let destination = ''
      if (shippingAddress) {
        destination = shippingAddress.name
          ? ` which is shipping to ${shippingAddress.name}`
          : shippingAddress.company
          ? ` which is shipping to ${shippingAddress.company}`
          : ''
      }
      let palletBox = match.find(
        ({ palletBoxId }) => palletBoxId === scannedPalletBoxId
      )

      if (palletBox && palletBox.sortLocation !== this.props.sortLocation) {
        logPalletScan(
          scannedPalletBoxId,
          this.props.package,
          CODE_LOCATIONS.PALLET_SCAN,
          {
            error: `Scanned palletbox is assigned to different location ${palletBox.sortLocation}.`,
            sortLocation: this.props.sortLocation
          }
        )
        throw new Error(
          `Package cannot be placed into this pallet box, please rescan ${packageId}${destination}.`
        )
      }

      if (!palletBox) {
        logPalletScan(
          scannedPalletBoxId,
          this.props.package,
          CODE_LOCATIONS.PALLET_SCAN,
          {
            message:
              'Could not find palletBox locally, retrieving from palletman.',
            sortLocation: this.props.sortLocation
          }
        )

        try {
          palletBox = (
            await palletBoxes({
              sortLocation: this.props.sortLocation,
              palletBoxId: scannedPalletBoxId,
              logisticsLocationId: this.props.currentLocation.id,
              since: moment().subtract(7, 'days').toISOString()
            })
          )[0]
        } catch (err) {
          elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
          logPalletScan(
            scannedPalletBoxId,
            this.props.package,
            CODE_LOCATIONS.PALLET_SCAN,
            {
              error: `Could not retrieve pallet box from palletman. ${err}`,
              sortLocation: this.props.sortLocation,
              elapsedTimeInMs
            }
          )
          throw new Error(
            `Package cannot be placed into this pallet box, please rescan ${packageId}${destination}.`
          )
        }
        elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
        logPalletScan(
          scannedPalletBoxId,
          this.props.package,
          CODE_LOCATIONS.PALLET_SCAN,
          {
            message: 'Succesfully retrieved pallet box from Palletman.',
            sortLocation: this.props.sortLocation,
            elapsedTimeInMs
          }
        )
      }

      if (palletBox.status === 'manifested') {
        elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
        logPalletScan(
          scannedPalletBoxId,
          this.props.package,
          CODE_LOCATIONS.PALLET_SCAN,
          {
            error: `The scanned pallet box: ${scannedPalletBoxId} has already been manifested. `,
            sortLocation: this.props.sortLocation,
            elapsedTimeInMs
          }
        )
        throw new Error(
          `Package cannot be placed into a manifested pallet box, please rescan ${packageId}${destination}.`
        )
      }

      if (palletBox.status === 'deleted') {
        elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
        logPalletScan(
          scannedPalletBoxId,
          this.props.package,
          CODE_LOCATIONS.PALLET_SCAN,
          {
            error: `The scanned pallet box: ${scannedPalletBoxId} has been deleted. `,
            sortLocation: this.props.sortLocation,
            elapsedTimeInMs
          }
        )
        throw new Error(
          `Package cannot be placed into a deleted pallet box, please rescan ${packageId}${destination}.`
        )
      }

      if (
        palletBox.status === 'closed' &&
        !this.props.allowScanningIntoClosedPalletBoxes
      ) {
        elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
        logPalletScan(
          scannedPalletBoxId,
          this.props.package,
          CODE_LOCATIONS.PALLET_SCAN,
          {
            error: `The scanned pallet box: ${scannedPalletBoxId} has already been closed. `,
            sortLocation: this.props.sortLocation,
            elapsedTimeInMs
          }
        )
        throw new Error(
          `Package cannot be placed into a closed pallet box, please rescan ${packageId}${destination}.`
        )
      }

      await this.sendSortEvent(
        this.props.package,
        packageId,
        scannedPalletBoxId,
        this.props.currentLocation.id
      )

      elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
      logPalletScan(
        scannedPalletBoxId,
        this.props.package,
        CODE_LOCATIONS.PALLET_SCAN,
        {
          message: `Succesfully scanned package ${this.props.package} into pallet box: ${scannedPalletBoxId}`,
          sortLocation: this.props.sortLocation,
          elapsedTimeInMs
        }
      )
      this.props.onPalletBox()
    } catch (error) {
      elapsedTimeInMs = this.getElapsedTimeInMs(startTime)
      logPalletScan(
        scannedPalletBoxId,
        this.props.package,
        CODE_LOCATIONS.PALLET_SCAN,
        {
          error: `Failed to scan package ${this.props.package} into pallet box: ${scannedPalletBoxId}. Error: ${error}`,
          sortLocation: this.props.sortLocation,
          elapsedTimeInMs
        }
      )
      this.props.onError(error)
    }
  }

  goToChuteMessage() {
    const sortLocation = this.props.sortLocation
    const palletBoxIdsWithNoChute =
      this.state.palletBoxIdsWithNoChute.join(', ')
    const chuteIds = this.state.chuteIds.join(', ')

    if (this.state.palletBoxIdsWithNoChute.length === 0) {
      if (this.state.chuteIds.length >= 1) {
        return CHUTE_MESSAGE_PALLET_0_CHUTE_1.replace(
          '[VAR_CHUTES]',
          `${chuteIds}`
        ).replace('[VAR_LOCATION]', `${sortLocation}`)
      } else {
        return CHUTE_MESSAGE_PALLET_0_CHUTE_0.replace(
          '[VAR_LOCATION]',
          `${sortLocation}`
        )
      }
    } else {
      if (this.state.chuteIds.length >= 1) {
        return CHUTE_MESSAGE_PALLET_1_CHUTE_1.replace(
          '[VAR_CHUTES]',
          `${chuteIds}`
        )
          .replace('[VAR_PALLET_BOXES]', `${palletBoxIdsWithNoChute}`)
          .replace('[VAR_LOCATION]', `${sortLocation}`)
      } else {
        return CHUTE_MESSAGE_PALLET_1_CHUTE_0.replace(
          '[VAR_PALLET_BOXES]',
          `${palletBoxIdsWithNoChute}`
        ).replace('[VAR_LOCATION]', `${sortLocation}`)
      }
    }
  }

  message() {
    const palletBoxIdsWithNoChute =
      this.state.palletBoxIdsWithNoChute.join(', ')
    const chuteIds = this.state.chuteIds.join(', ')

    if (this.state.palletBoxIdsWithNoChute.length === 0) {
      if (this.state.chuteIds.length >= 1) {
        return MESSAGE_PALLET_0_CHUTE_1.replace('[VAR_CHUTES]', `${chuteIds}`)
      } else {
        return MESSAGE_PALLET_0_CHUTE_0
      }
    } else {
      if (this.state.chuteIds.length >= 1) {
        return MESSAGE_PALLET_1_CHUTE_1.replace(
          '[VAR_CHUTES]',
          `${chuteIds}`
        ).replace('[VAR_PALLET_BOXES]', `${palletBoxIdsWithNoChute}`)
      } else {
        return MESSAGE_PALLET_1_CHUTE_0.replace(
          '[VAR_PALLET_BOXES]',
          `${palletBoxIdsWithNoChute}`
        )
      }
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <label className="col-md-5">{this.goToChuteMessage()}</label>
            <span className="col-md-12">
              <TextField
                id="text-field"
                name="palletBox"
                label={this.message()}
                value={this.state.palletBox}
                onChange={this.onScanBoxChange}
                onKeyDown={this.onKey}
                autoFocus
              />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

PalletBox.propTypes = {
  onPalletBox: PropTypes.func,
  onError: PropTypes.func,
  onScan: PropTypes.func,
  match: PropTypes.array,
  package: PropTypes.string,
  shipment: PropTypes.object,
  sortLocation: PropTypes.string,
  currentLocation: PropTypes.object
}

export default connect((state, ownProps) => {
  return {
    currentLocation: state.app.currentLocation,
    match: ownProps.match,
    package: ownProps.package,
    onPalletBox: ownProps.onPalletBox,
    onError: ownProps.onError,
    onScan: ownProps.onScan,
    shipment: ownProps.shipment,
    sortLocation: ownProps.sortLocation,
    allowScanningIntoClosedPalletBoxes:
      state.app.currentLocation.settings.allowScanningIntoClosedPalletBoxes ||
      false,
    useBarcodeScanForPackageId:
      state.app.currentLocation.settings.useBarcodeScanForPackageId || false
  }
})(PalletBox)
