const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'WEBSHELL_LOG_IN':
      return {
        ...state,
        isAuthenticated: true,
        profile: action.profile
      }
    case 'WEBSHELL_LOG_OUT':
      return {
        ...state,
        isAuthenticated: false,
        profile: null,
        config: null
      }
    case 'WEBSHELL_APP_CONFIG':
      return {
        ...state,
        config: action.config
      }
    default:
      return state
  }
}

export default reducer
