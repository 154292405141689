import React, { Component } from 'react'
import Package from './Package'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import PropTypes from 'prop-types'
import PalletBox from './PalletBox'
import { ConfirmMove } from './ConfirmMove'
import { dispatchError, dispatchMessage } from '../redux/notifications/actions'
import { dispatchWait } from '../redux/app/actions'

export class SortPackageApp extends Component {
  constructor(props) {
    super(props)
    autobind(this)
    this.state = { step: 'scan-package' }
  }

  body() {
    const {
      step,
      shipment,
      palletBoxes,
      sortLocation,
      package: pkg
    } = this.state

    let verificationDisplay = this.props.verification()

    if (verificationDisplay) {
      return verificationDisplay
    }

    switch (step) {
      case 'scan-package':
        return (
          <Package
            match={this.state.shipment}
            onScan={() => {
              dispatchWait(true)
              dispatchError(null, null)
            }}
            onError={({ message }) => {
              dispatchWait(false)
              dispatchError(message, 'error')
            }}
            onPackage={({
              package: pkg,
              shipment,
              sortLocation,
              palletBoxes,
              step
            }) => {
              dispatchWait(false)
              this.setState({
                step,
                package: pkg,
                shipment,
                sortLocation,
                palletBoxes
              })
            }}
          />
        )
      case 'match-pallet':
        return (
          <PalletBox
            match={palletBoxes}
            package={pkg}
            shipment={shipment}
            sortLocation={sortLocation}
            onScan={() => {
              dispatchMessage(null, null)
              dispatchWait(true)
            }}
            onError={({ message }) => {
              dispatchError(message, 'error')
              dispatchWait(false)
              this.setState({ step: 'scan-package' })
            }}
            onPalletBox={() => {
              dispatchWait(false)
              dispatchMessage(
                'The package has been sorted successfully.',
                'success_pb_scan'
              )
              this.setState({ step: 'scan-package', shipment: null })
            }}
          />
        )
      case 'move-package':
        return (
          <ConfirmMove
            package={pkg}
            onConfirm={() => this.setState({ step: 'match-pallet' })}
            onDeny={() =>
              this.setState({ step: 'scan-package', shipment: null })
            }
          />
        )
      default:
        throw new Error('Unrecognized step')
    }
  }

  render() {
    return (
      <div>
        <div className="container h4">Sort Package</div>
        {this.body()}
      </div>
    )
  }
}

SortPackageApp.propTypes = {
  verification: PropTypes.func
}

export default connect((state, ownProps) => {
  return {
    verification: ownProps.verification
  }
})(SortPackageApp)
