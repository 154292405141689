import { defaults } from 'lodash'
import { sendToRiver } from './river'
import store from '../redux'

export function logTruckInfo(truckReference, palletBoxId, codeLocation, info) {
  try {
    const state = store.getState()
    const userScannedText =
      codeLocation === 'REMOVE_PALLET_FROM_TRUCK' ? palletBoxId : truckReference
    const fulfillmentLocationId =
      state.app.currentLocation.fulfillmentLocationId
    const notifications = state.notifications
    const userProfile = state.webShell?.profile?.userProfile && {
      email: state.webShell.profile.userProfile.email,
      name: state.webShell.profile.userProfile.name,
      sub: state.webShell.profile.userProfile.sub
    }
    const moreInfo = defaults(
      { notifications, ...(userProfile && { userProfile }) },
      info
    )

    buildDropAndSend(
      userScannedText,
      codeLocation,
      fulfillmentLocationId,
      '',
      palletBoxId,
      truckReference,
      moreInfo
    )
  } catch (err) {}
}

export function logPackageScan(pkgId, codeLocation, info) {
  try {
    const state = store.getState()
    const userScannedText = pkgId
    const packageId = pkgId
    const fulfillmentLocationId =
      state.app.currentLocation.fulfillmentLocationId
    const notifications = state.notifications
    const userProfile = state.webShell &&
      state.webShell.profile &&
      state.webShell.profile.userProfile && {
        email: state.webShell.profile.userProfile.email,
        name: state.webShell.profile.userProfile.name,
        sub: state.webShell.profile.userProfile.sub
      }
    const moreInfo = defaults(
      { notifications, ...(userProfile && { userProfile }) },
      info
    )

    buildDropAndSend(
      userScannedText,
      codeLocation,
      fulfillmentLocationId,
      packageId,
      '',
      '',
      moreInfo
    )
  } catch (err) {}
}

export function logPalletScan(palletBoxId, packageId, codeLocation, info) {
  try {
    const state = store.getState()
    const userScannedText = palletBoxId
    const fulfillmentLocationId =
      state.app.currentLocation.fulfillmentLocationId
    const notifications = state.notifications
    const userProfile = state.webShell?.profile?.userProfile && {
      email: state.webShell.profile.userProfile.email,
      name: state.webShell.profile.userProfile.name,
      sub: state.webShell.profile.userProfile.sub
    }
    const moreInfo = defaults(
      { notifications, ...(userProfile && { userProfile }) },
      info
    )

    buildDropAndSend(
      userScannedText,
      codeLocation,
      fulfillmentLocationId,
      packageId,
      palletBoxId,
      '',
      moreInfo
    )
  } catch (err) {}
}

function buildDropAndSend(
  userScannedText,
  codeLocation,
  fulfillmentLocationId,
  packageId,
  palletBoxId,
  truckReference,
  moreInfo
) {
  const drop = defaults(
    {
      userScannedText,
      codeLocation,
      fulfillmentLocationId,
      packageId,
      palletBoxId,
      truckReference
    },
    moreInfo
  )

  sendToRiver(drop)
}
