import React, { Component } from 'react'
import TextField from '@cimpress/react-components/lib/TextField'
import autobind from 'react-autobind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { palletBoxes, deletePalletBox } from '../authentication/palletman'
import { moveShipments, getPalletBox } from '../authentication/sapi'

export class PalletBox extends Component {
  constructor(props) {
    super(props)
    autobind(this)
    this.state = {
      palletBoxId: ''
    }
  }

  onScanBoxChange({ target: { value } }) {
    this.setState({ palletBoxId: value.trim() })
  }

  onKey({ key }) {
    if (key !== 'Enter') return

    this.onPalletBox(this.state.palletBoxId)
  }

  async onPalletBox(value) {
    if (!value) return
    try {
      this.props.onScan()

      let palletBox = (
        await palletBoxes({
          palletBoxId: value,
          logisticsLocationId: this.props.currentLocation.id
        })
      )[0]

      this.props.checkPalletBox(
        palletBox,
        this.props.fromPalletBoxId,
        this.props.sortLocation
      )

      let pb = await getPalletBox(value, this.props.currentLocation.id)

      if (!pb) {
        throw new Error(`Pallet box ${value} not found.`)
      }

      if (this.props.fromSapiPalletBoxId) {
        await moveShipments(
          this.props.fromSapiPalletBoxId,
          pb.id,
          true,
          this.props.currentLocation.id
        )
        await deletePalletBox(
          this.props.fromPalletBoxId,
          this.props.currentLocation.id
        )
      }

      this.props.onPalletBox(value, pb.id, palletBox.sortLocation)
    } catch (error) {
      this.props.onError(error)
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <TextField
              id="text-field"
              name="palletBox"
              label={'Please scan pallet box'}
              value={this.state.palletBoxId}
              onChange={this.onScanBoxChange}
              onKeyDown={this.onKey}
              autoFocus
            />
          </div>
        </div>
      </div>
    )
  }
}

PalletBox.propTypes = {
  onPalletBox: PropTypes.func,
  onError: PropTypes.func,
  onScan: PropTypes.func,
  checkPalletBox: PropTypes.func,
  fromPalletBoxId: PropTypes.string,
  fromSapiPalletBoxId: PropTypes.string,
  sortLocation: PropTypes.string,
  currentLocation: PropTypes.object
}

export default connect((state, ownProps) => {
  return {
    currentLocation: state.app.currentLocation,
    fromPalletBoxId: ownProps.fromPalletBoxId,
    fromSapiPalletBoxId: ownProps.fromSapiPalletBoxId,
    sortLocation: ownProps.sortLocation,
    onPalletBox: ownProps.onPalletBox,
    onError: ownProps.onError,
    onScan: ownProps.onScan,
    checkPalletBox: ownProps.checkPalletBox
  }
})(PalletBox)
