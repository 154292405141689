import React, { Component } from 'react'
import Button from '@cimpress/react-components/lib/Button'
import { Alert } from '@cimpress/react-components'
import autobind from 'react-autobind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { dispatchMessage } from '../redux/notifications/actions'

export class ConfirmPbLoading extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  async onDeny() {
    const restoreState = {
      palletBoxId: '',
      step: 'scan-pallet-box',
      titleText: 'Scan Pallet Box to Truck',
      palletBoxInfo: null
    }
    this.props.setAppState(restoreState)
  }

  async onConfirm() {
    const { palletBoxId, palletBoxInfo } = this.props
    const titleText = palletBoxInfo.name
      ? `Scan Truck for ${palletBoxInfo.name} pallet box`
      : 'Scan Truck'
    this.props.setAppState({
      palletBoxId: palletBoxId,
      step: 'load-pallet-box-into-truck',
      titleText: titleText
    })
    dispatchMessage('Scan pallet successful.', 'success')
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Alert
              message="CAUTION: This pallet is to be loaded on the tail of the truck. Please confirm."
              dismissible={false}
              status="warning"
              key="confirmAlert"
            ></Alert>
            <div className="col-md-12">
              <Button
                name="confirmButton"
                type="default"
                size="default"
                onClick={this.onConfirm}
              >
                CONFIRM
              </Button>
              <div className="col-md-12">
                <br />
              </div>
            </div>
            <div className="col-md-12">
              <Button
                name="denyButton"
                type="default"
                size="default"
                onClick={this.onDeny}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ConfirmPbLoading.propTypes = {
  setAppState: PropTypes.func,
  palletBoxInfo: PropTypes.object,
  palletBoxId: PropTypes.string
}

export default connect((state, ownProps) => {
  return {
    setAppState: ownProps.setAppState,
    palletBoxInfo: ownProps.palletBoxInfo,
    palletBoxId: ownProps.palletBoxId
  }
})(ConfirmPbLoading)
