const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'DISPATCH_ALLOW':
      return {
        ...state,
        allow: action.allow
      }
    case 'DISPATCH_WAIT':
      return {
        ...state,
        wait: action.wait
      }
    case 'DISPATCH_LOCATIONS':
      return {
        ...state,
        locations: action.locations
      }
    case 'DISPATCH_CURRENT_LOCATION':
      return {
        ...state,
        currentLocation: action.currentLocation,
        paths: {
          redirectPath: action.currentLocation
            ? `/sort-package/${action.currentLocation.id}`
            : '/select-location',
          sortPackage: action.currentLocation
            ? `/sort-package/${action.currentLocation.id}`
            : '/sort-package/',
          loadTruck: action.currentLocation
            ? `/load-truck/${action.currentLocation.id}`
            : '/load-truck/',
          movePackages: action.currentLocation
            ? `/move-packages/${action.currentLocation.id}`
            : '/move-packages',
          selectLocation: '/select-location'
        }
      }
    default:
      return state
  }
}

export default reducer
