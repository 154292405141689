import { makeAuthenticatedRequest } from './authenticationProxy'
import appConfig from '../app-config.json'

const PALLETMAN_BASEURL = appConfig.PALLETMAN_BASEURL
const cacheTTL = 5 * 60 * 1000 // 5 minutes

export async function deletePalletBox(palletBoxId, logisticsLocationId) {
  await makeAuthenticatedRequest({
    url: `${PALLETMAN_BASEURL}/api/v1/palletBoxes`,
    method: 'PATCH',
    params: { logisticsLocationId, palletBoxId },
    data: [
      {
        op: 'replace',
        path: '/status',
        value: 'closed'
      }
    ]
  })

  await makeAuthenticatedRequest({
    url: `${PALLETMAN_BASEURL}/api/v1/palletBoxes`,
    method: 'PATCH',
    params: { logisticsLocationId, palletBoxId },
    data: [
      {
        op: 'replace',
        path: '/status',
        value: 'deleted'
      }
    ]
  })
}

export async function palletBoxes({
  sortLocation,
  logisticsLocationId,
  status,
  palletBoxId,
  since
}) {
  const palletBoxes = []
  let baseParams = {
    logisticsLocationId,
    sortLocation,
    status,
    palletBoxId,
    since
  }
  let next
  do {
    let currentPage
    ;({ palletBoxes: currentPage, links: { next: { href: next } = {} } = {} } =
      await makeAuthenticatedRequest({
        url: next || `${PALLETMAN_BASEURL}/api/v1/palletBoxes`,
        params: next ? {} : baseParams,
        cache: { ttl: cacheTTL }
      }).catch((error) => {
        if (error.status === 404) {
          return { palletBoxes: [] }
        }
        throw error
      }))
    palletBoxes.push(...currentPage)
  } while (next)

  if (!palletBoxes || palletBoxes.length < 1) {
    throw new Error(
      generateError({ sortLocation, logisticsLocationId, status, palletBoxId })
    )
  }

  return palletBoxes
}

function generateError({
  sortLocation,
  logisticsLocationId,
  status,
  palletBoxId
}) {
  let params = []
  if (sortLocation) {
    params.push(` sortLocation: ${sortLocation}`)
  }
  if (logisticsLocationId) {
    params.push(` logisticsLocationId: ${logisticsLocationId}`)
  }
  if (status) {
    params.push(` status: ${status}`)
  }
  if (palletBoxId) {
    params.push(` palletBoxId: ${palletBoxId}`)
  }

  return `No pallet boxes found for${params}`
}
