import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper'

const CLIENT_ID = 'kZgkkEDxY73Uxb8h45i4xJWjGc4BDfE5'

const auth0 = new CentralizedAuth({ clientID: CLIENT_ID })

const state = {
  auth0: auth0,
  isAuthenticated: auth0.isLoggedIn(),
  profile: auth0.getProfile()
}

export default state
