const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'DISPATCH_ERROR':
      return {
        ...state,
        error: action.error,
        audioType: action.audioType
      }
    case 'DISPATCH_MESSAGE':
      return {
        ...state,
        message: action.message,
        audioType: action.audioType
      }
    default:
      return state
  }
}

export default reducer
