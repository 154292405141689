import React, { Component } from 'react'
import TextField from '@cimpress/react-components/lib/TextField'
import autobind from 'react-autobind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getPalletBox, getTruckById } from '../authentication/sapi'
import { dispatchMessage } from '../redux/notifications/actions'
import { palletScanningEvent } from '../authentication/ses'
import { logPalletScan } from '../logging/logger'
import CODE_LOCATIONS from '../logging/codeLocation'
import time from '../logging/time'

const TAIL_LOAD_SORT_LOCATIONS = [
  'UPS Canada Saver 1P',
  'UPS Canada Saver 1P Manual',
  'UPS Under Next Day Air - 1P',
  'UPS UNDER Next Day Air - 1P',
  'UPS Over Next Day Air -1P',
  'UPS Over NDA Manual - 1P',
  'UPS Over FDA Next Day Air - 1P',
  'UPS Under Mail Innovations',
  'UPS Over FDA Manual',
  'UPS Over FDA'
]
const TAIL_LOAD_LOG_LOCATIONS = ['WINDSOR', 'WINDSOR-DEMO']

export class PalletBox extends Component {
  constructor(props) {
    super(props)
    this.state = { palletBoxId: '' }
    autobind(this)
  }

  onScanBoxChange({ target: { value } }) {
    this.setState({ palletBoxId: value.trim() })
  }

  onKey({ key }) {
    if (key !== 'Enter') return
    this.onPalletBox(this.state.palletBoxId)
  }

  requiresBlockingStep(palletBoxInfo) {
    const { currentLocation } = this.props
    const { name: sortLocation } = palletBoxInfo
    if (
      TAIL_LOAD_LOG_LOCATIONS.includes(currentLocation.id.toUpperCase()) &&
      TAIL_LOAD_SORT_LOCATIONS.includes(sortLocation)
    ) {
      return true
    }
    return false
  }

  async onPalletBox(value) {
    let startTime, elapsedTimeInMs
    startTime = performance.now()
    if (!value) return
    if (this.props.match && value !== this.props.match) {
      this.props.onError({
        message: `Please rescan pallet box ${this.props.match}`
      })
      return
    }

    // first check that the pallet box doesn't have a truck assoc already
    const palletBoxInfo = await getPalletBox(
      value,
      this.props.currentLocation.id
    )

    if (palletBoxInfo && palletBoxInfo.truckId) {
      const truckInfo = await getTruckById(
        palletBoxInfo.truckId,
        this.props.currentLocation.id
      )
      this.props.onPalletBox({
        palletBoxId: value,
        step: 'remove-pallet-box',
        titleText: 'Remove Pallet Box?',
        palletBoxInfo: palletBoxInfo,
        truckInfo: truckInfo
      })
      elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
      logPalletScan(value, '', CODE_LOCATIONS.PALLET_SCAN_TO_TRUCK, {
        message: `Pallet Box '${value}' has already been loaded into a truck.`,
        elapsedTimeInMs
      })
    } else if (palletBoxInfo && palletBoxInfo.status === 'open') {
      elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
      logPalletScan(value, '', CODE_LOCATIONS.PALLET_SCAN_TO_TRUCK, {
        error: `Pallet Box '${value}' is open. Please close the pallet box and then scan again.`,
        elapsedTimeInMs
      })
      this.props.onError({
        message: `Pallet Box '${value}' is open. Please close the pallet box and then scan again.`
      })
      this.setState({ palletBoxId: '' })
    } else if (palletBoxInfo) {
      const titleText = palletBoxInfo.name
        ? `Scan Truck for ${palletBoxInfo.name} pallet box`
        : 'Scan Truck'

      try {
        await palletScanningEvent({
          palletBoxId: value,
          logisticsLocationId: this.props.currentLocation.id,
          user: this.props.profile.name
        })
      } catch (scanError) {
        elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
        logPalletScan(value, '', CODE_LOCATIONS.PALLET_SCAN_TO_TRUCK, {
          error: `Problem encountered while recording scan event, please rescan pallet box. Detail - ${scanError}`,
          elapsedTimeInMs
        })
        this.props.onError({
          message: `Problem encountered while recording scan event, please rescan pallet box. Detail - ${scanError}`
        })
        this.setState({ palletBoxId: '' })
        return
      }

      if (this.requiresBlockingStep(palletBoxInfo)) {
        this.props.onPalletBox({
          palletBoxId: value,
          step: 'load-pb-scan-confirmation',
          titleText: '',
          palletBoxInfo
        })
      } else {
        dispatchMessage('Scan pallet successful.', 'success')
        this.props.onPalletBox({
          palletBoxId: value,
          step: 'load-pallet-box-into-truck',
          titleText: titleText
        })
        elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
        logPalletScan(value, '', CODE_LOCATIONS.PALLET_SCAN_TO_TRUCK, {
          message: 'Scan pallet box successful.',
          elapsedTimeInMs
        })
      }
    } else {
      elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
      logPalletScan(
        this.state.palletBoxId,
        '',
        CODE_LOCATIONS.PALLET_SCAN_TO_TRUCK,
        {
          error: `Pallet Box '${this.state.palletBoxId}' not found. Please scan again.`,
          elapsedTimeInMs
        }
      )
      this.props.onError({
        message: `Pallet Box '${this.state.palletBoxId}' not found. Please scan again.`
      })
      this.setState({ palletBoxId: '' })
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <TextField
              id="text-field"
              name="palletbox"
              label={`Please scan palletbox ${this.props.match ? 'again' : ''}`}
              value={this.state.palletBoxId}
              onChange={this.onScanBoxChange}
              onKeyPress={this.onKey}
              autoFocus
            />
          </div>
        </div>
      </div>
    )
  }
}

PalletBox.propTypes = {
  onPalletBox: PropTypes.func,
  onError: PropTypes.func,
  currentLocation: PropTypes.object,
  match: PropTypes.string
}

export default connect((state, ownProps) => {
  return {
    onPalletBox: ownProps.onPalletBox,
    onError: ownProps.onError,
    currentLocation: state.app.currentLocation,
    profile: state.webShell.isAuthenticated
      ? state.webShell.profile.userProfile
      : state.auth.profile,
    match: ownProps.match
  }
})(PalletBox)
