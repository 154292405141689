import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import $ from 'jquery'
import * as FullStory from '@fullstory/browser'
FullStory.init({ orgId: '97CT5' })

$(document).ready(() => {
  let rootElement = document.getElementById('root')
  if (rootElement) {
    ReactDOM.render(<Root />, rootElement)
  }
})
