import { getProfile } from './auth'
import { getLocations, getLocation } from './logisticsLocation'
import { makeAuthenticatedRequest } from './authenticationProxy'
import { dispatchError } from '../redux/notifications/actions'

const COAM_BASE_URL = 'https://api.cimpress.io/auth/access-management/v1'
const LOGISTICS_LOCATION_RESOURCE_TYPE = 'logistics-location'
const UPDATE_SHIPMENTS_PERM = 'update:shipments'
const CACHE_TTL = 5 * 1000 * 60 // 5 minutes

export async function allowedLocations() {
  const profile = getProfile()
  if (!profile) {
    return []
  }
  const locations = await makeAuthenticatedRequest({
    url: `${COAM_BASE_URL}/principals/${profile.sub}/permissions/${LOGISTICS_LOCATION_RESOURCE_TYPE}`,
    cache: { ttl: CACHE_TTL }
  })
  const logisticsLocationIds = (locations || [])
    .filter(({ permissions }) =>
      permissions.find((permission) => permission === UPDATE_SHIPMENTS_PERM)
    )
    .map(({ identifier }) => identifier)

  if (logisticsLocationIds.includes('*')) {
    return await getLocations()
  } else {
    return (
      await Promise.all(
        logisticsLocationIds.map(async (logisticsLocationId) => {
          try {
            return await getLocation(logisticsLocationId)
          } catch (err) {
            dispatchError(err.message, 'error')
          }
        })
      )
    ).filter((location) => location)
  }
}
