import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import PropTypes from 'prop-types'
import PalletBox from './PalletBox'
import { dispatchError, dispatchMessage } from '../redux/notifications/actions'
import { dispatchWait } from '../redux/app/actions'

const SCAN_TO_TITLE = 'Scan To Pallet Box'
const SCAN_FROM_TITLE = 'Scan From Pallet Box'

export class MovePackagesApp extends Component {
  constructor(props) {
    super(props)
    autobind(this)
    this.state = { step: 'scan-from-pallet', title: SCAN_FROM_TITLE }
  }

  fromCheck(palletBox) {
    if (palletBox.status === 'open') {
      throw new Error(
        'Cannot move packages from an open pallet box. Please close the pallet box before moving packages.'
      )
    } else if (palletBox.status === 'manifested') {
      throw new Error('Cannot move packages from a manifested pallet box.')
    }

    return
  }

  toCheck(palletBox, fromPalletBoxId, sortLocation) {
    if (palletBox.palletBoxId === fromPalletBoxId) {
      throw new Error('Cannot move packages to the same pallet box.')
    } else if (palletBox.status === 'manifested') {
      throw new Error('Cannot move packages to a manifested pallet box.')
    } else if (palletBox.sortLocation !== sortLocation) {
      throw new Error(
        `Cannot move packages to a mismatching sort location. ${fromPalletBoxId} sort location: ${sortLocation}. ${palletBox.palletBoxId} sort location: ${palletBox.sortLocation}.`
      )
    }

    return
  }

  body() {
    const { step, fromPalletBoxId, fromSapiPalletBoxId, sortLocation, title } =
      this.state

    let verificationDisplay = this.props.verification()

    if (verificationDisplay) {
      return verificationDisplay
    }

    switch (step) {
      case 'scan-from-pallet':
        return (
          <PalletBox
            title={title}
            onScan={() => {
              dispatchWait(true)
              dispatchError(null, null)
            }}
            onError={({ message }) => {
              dispatchWait(false)
              dispatchError(message, 'error')
            }}
            checkPalletBox={this.fromCheck}
            onPalletBox={(
              fromPalletBoxId,
              fromSapiPalletBoxId,
              sortLocation
            ) => {
              dispatchWait(false)
              dispatchMessage('Scan pallet successful', 'success')
              this.setState({
                fromPalletBoxId,
                fromSapiPalletBoxId,
                sortLocation,
                step: 'scan-to-pallet',
                title: SCAN_TO_TITLE
              })
            }}
          />
        )
      case 'scan-to-pallet':
        return (
          <PalletBox
            title={title}
            fromPalletBoxId={fromPalletBoxId}
            fromSapiPalletBoxId={fromSapiPalletBoxId}
            sortLocation={sortLocation}
            onScan={() => {
              dispatchMessage(null, null)
              dispatchWait(true)
            }}
            onError={({ message }) => {
              dispatchError(message, 'error')
              dispatchWait(false)
              this.setState({
                step: 'scan-from-pallet',
                fromSapiPalletBoxId: null,
                fromPalletBoxId: null,
                sortLocation: null,
                title: SCAN_FROM_TITLE
              })
            }}
            checkPalletBox={this.toCheck}
            onPalletBox={(toPalletBoxId) => {
              dispatchWait(false)
              dispatchMessage(
                `The packages have been moved successfully from ${fromPalletBoxId} to ${toPalletBoxId}.`,
                'success'
              )
              this.setState({
                step: 'scan-from-pallet',
                fromSapiPalletBoxId: null,
                fromPalletBoxId: null,
                sortLocation: null,
                title: SCAN_FROM_TITLE
              })
            }}
          />
        )
      default:
        throw new Error('Unrecognized step')
    }
  }

  render() {
    return (
      <div>
        <div className="container h4">{this.state.title}</div>
        {this.body()}
      </div>
    )
  }
}

MovePackagesApp.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  allow: PropTypes.bool,
  wait: PropTypes.bool,
  verification: PropTypes.func
}

export default connect((state, ownProps) => {
  return {
    isAuthenticated:
      state.webShell.isAuthenticated || state.auth.isAuthenticated,
    allow: state.app.allow,
    wait: state.app.wait,
    verification: ownProps.verification
  }
})(MovePackagesApp)
