const state = {
  allow: false,
  wait: true,
  currentLocation: null,
  locations: [],
  paths: {
    redirectPath: '/select-location',
    sortPackage: '/sort-package/',
    selectLocation: '/select-location',
    loadTruck: '/load-truck/'
  }
}

export default state
