import { makeAuthenticatedRequest } from './authenticationProxy'
import appConfig from '../app-config.json'

const SORTCOM_BASEURL = appConfig.SORTCOM_BASEURL

export async function sort({ packageId, logisticsLocationId, palletBoxId }) {
  await makeAuthenticatedRequest({
    url: `${SORTCOM_BASEURL}/api/v1/sortedPackages`,
    method: 'POST',
    data: {
      logisticsLocationId,
      packageId,
      palletBoxId
    }
  })
}
