import React, { Component } from 'react'
import Button from '@cimpress/react-components/lib/Button'
import autobind from 'react-autobind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { removePalletBoxFromTruck } from '../authentication/sapi'
import { playAudio } from '../audio/audio'
import CODE_LOCATIONS from '../logging/codeLocation'
import { logTruckInfo } from '../logging/logger'
import time from '../logging/time'

export class RemovePalletBox extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  componentDidMount() {
    playAudio('information')
  }

  async onConfirm() {
    let startTime, elapsedTimeInMs
    startTime = performance.now()
    try {
      await removePalletBoxFromTruck(
        this.props.palletBoxInfo.truckId,
        this.props.palletBoxInfo.id,
        this.props.currentLocation.id
      )
      elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
      logTruckInfo(
        this.props.palletBoxInfo.truckId,
        this.props.palletBoxInfo.externalId,
        CODE_LOCATIONS.REMOVE_PALLET_FROM_TRUCK,
        {
          message: 'Pallet Box successfully removed from Truck',
          elapsedTimeInMs
        }
      )
      this.props.onConfirm({
        message: 'Pallet Box successfully removed from Truck'
      })
    } catch (error) {
      elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
      logTruckInfo(
        this.props.palletBoxInfo.truckId,
        this.props.palletBoxInfo.externalId,
        CODE_LOCATIONS.REMOVE_PALLET_FROM_TRUCK,
        {
          error: `Encountered an error while removing pallet box from truck: ${error}`,
          elapsedTimeInMs
        }
      )
      this.props.onError(error)
    }
  }

  render() {
    const truckInfo = this.props.truckInfo
    let allowRemove = true
    let warningMessage
    if (truckInfo && !truckInfo.closedAt) {
      warningMessage = `The Pallet Box, ${this.props.palletBoxInfo.externalId}, has already been loaded into a truck. Would you like to remove this Pallet Box from the truck?`
    } else {
      warningMessage = `The Pallet Box, ${this.props.palletBoxInfo.externalId}, has already been loaded into a manifested truck and cannot be removed.`
      allowRemove = false
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>{warningMessage}</p>
            {allowRemove && (
              <div>
                <div className="col-md-12">
                  <Button
                    name="confirmButton"
                    type="primary"
                    size="default"
                    onClick={this.onConfirm}
                  >
                    {' '}
                    Remove Pallet Box from Truck{' '}
                  </Button>
                </div>
                <div className="col-md-12">
                  <br />
                  <br />
                </div>
              </div>
            )}
            <div className="col-md-12">
              <Button
                name="denyButton"
                type="default"
                size="default"
                onClick={this.props.onDeny}
              >
                {' '}
                Cancel{' '}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RemovePalletBox.propTypes = {
  palletBoxInfo: PropTypes.object,
  currentLocation: PropTypes.object,
  onError: PropTypes.func,
  onConfirm: PropTypes.func,
  onDeny: PropTypes.func
}

export default connect((state, ownProps) => {
  return {
    currentLocation: state.app.currentLocation
  }
})(RemovePalletBox)
