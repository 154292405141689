import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import PropTypes from 'prop-types'
import Truck from './Truck'
import PalletBox from './PalletBox'
import RemovePalletBox from './RemovePalletBox'
import ConfirmPbLoading from './confirmPbLoading'
import { dispatchError, dispatchMessage } from '../redux/notifications/actions'

export class TruckLoaderApp extends Component {
  constructor(props) {
    super(props)
    autobind(this)
    this.state = {
      step: 'scan-pallet-box',
      palletBoxId: '',
      titleText: 'Scan Pallet Box to Truck'
    }
  }

  body() {
    const { step } = this.state
    const restoreState = {
      palletBoxId: '',
      step: 'scan-pallet-box',
      titleText: 'Scan Pallet Box to Truck',
      palletBoxInfo: null
    }

    let verificationDisplay = this.props.verification()

    if (verificationDisplay) {
      return verificationDisplay
    }

    switch (step) {
      case 'scan-pallet-box':
        return (
          <PalletBox
            match={this.state.palletBoxId}
            onPalletBox={({
              palletBoxId,
              step,
              titleText,
              palletBoxInfo,
              truckInfo
            }) => {
              this.setState({
                palletBoxId,
                step,
                titleText,
                palletBoxInfo,
                truckInfo
              })
              dispatchError(null, null)
            }}
            onError={({ message }) => {
              dispatchError(message, 'error')
            }}
          />
        )

      case 'load-pb-scan-confirmation':
        return (
          <ConfirmPbLoading
            setAppState={({
              palletBoxId,
              step,
              titleText,
              palletBoxInfo,
              truckInfo
            }) => {
              this.setState({
                palletBoxId,
                step,
                titleText,
                palletBoxInfo,
                truckInfo
              })
              dispatchError(null, null)
            }}
            palletBoxInfo={this.state.palletBoxInfo}
            palletBoxId={this.state.palletBoxId}
          />
        )
      case 'load-pallet-box-into-truck':
        return (
          <Truck
            palletBoxId={this.state.palletBoxId}
            onPalletBoxLoad={({ message, palletBoxId, step, titleText }) => {
              this.setState({ palletBoxId, step, titleText })
              dispatchMessage(message, 'success')
              dispatchError(null, null)
            }}
            onError={({ err, step, titleText }) => {
              this.setState({ step, titleText })
              dispatchError(err.message, 'error')
            }}
          />
        )
      case 'remove-pallet-box':
        return (
          <RemovePalletBox
            palletBoxInfo={this.state.palletBoxInfo}
            truckInfo={this.state.truckInfo}
            onConfirm={({ message }) => {
              this.setState(restoreState)
              dispatchMessage(message, 'success')
            }}
            onDeny={() => this.setState(restoreState)}
            onError={(err) => {
              this.setState(restoreState)
              dispatchError(err.message, 'error')
            }}
          />
        )
      default:
        throw new Error('Unrecognized step')
    }
  }

  render() {
    return (
      <div>
        <div className="container h4">{this.state.titleText}</div>
        {this.body()}
      </div>
    )
  }
}

TruckLoaderApp.propTypes = {
  currentLocation: PropTypes.object,
  verification: PropTypes.func
}

export default connect((state, ownProps) => {
  return {
    currentLocation: state.app.currentLocation,
    verification: ownProps.verification
  }
})(TruckLoaderApp)
