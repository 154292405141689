// Import auth initial state
import auth from './authentication/state'
// Import webshell initial state
import webShell from './webshell/state'
// Import notifications initial state
import notifications from './notifications/state'
// Import app initial state
import app from './app/state'
// Merge all state into an initial state object and export it
const state = {
  auth,
  webShell,
  notifications,
  app
}

export default state
