import { makeAuthenticatedRequest } from './authenticationProxy'
import appConfig from '../app-config.json'
import { merge, uniq } from 'lodash'

import diffApply from 'just-diff-apply'
const apply = diffApply.diffApply
const converter = diffApply.jsonPatchPathConverter

const SORTMAN_BASEURL = appConfig.SORTMAN_BASEURL

export async function sort({ shipment }, fulfillmentLocationId) {
  // Drop the sort location originally assigned to the shipment
  for (let i = 0; i < shipment.packages.length; i++) {
    if (
      shipment.packages[i].metadata &&
      shipment.packages[i].metadata.sortation &&
      shipment.packages[i].metadata.sortation.sortLocation
    ) {
      delete shipment.packages[i].metadata.sortation.sortLocation
    }
  }

  shipment = merge({ fulfillmentLocationId }, shipment)
  const { changes } = await makeAuthenticatedRequest({
    url: `${SORTMAN_BASEURL}/api/v0/sapi/hooks/pre-ship`,
    method: 'POST',
    data: { createShipmentRequest: shipment, sortationType: 'manual' }
  })

  apply(shipment, changes, converter)
  const [sortLocation, other] = uniq(
    shipment.packages
      .map(
        ({ metadata: { sortation: { sortLocation } = {} } = {} }) =>
          sortLocation
      )
      .filter((x) => x)
  )

  if (other) throw new Error('Multiple sort locations for shipment')
  if (!sortLocation)
    throw new Error('This package has no sort rules associated with it')

  return sortLocation
}
