import { combineReducers } from 'redux'

import auth from './authentication/reducers'
import webShell from './webshell/reducers'
import notifications from './notifications/reducers'
import app from './app/reducers'
// Import all reducers and combine them here
export default combineReducers({
  auth,
  webShell,
  notifications,
  app
})
