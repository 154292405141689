import * as authenticationProxy from './authenticationProxy'
import { logPackageScan } from '../logging/logger'
import CODE_LOCATIONS from '../logging/codeLocation'
import appConfig from '../app-config.json'
import moment from 'moment'
import { uniqBy } from 'lodash'
import store from '../redux'
const SAPI_BASEURL = appConfig.SAPI_BASEURL
const WEBSHELL_BARCODE_SEARCH_KEY = 'isBarcodeSearch'
const SAPI_QUERY_START_VALUE = 1
const SAPI_QUERY_LIMIT_VALUE = 5
const SAPI_QUERY_PERIOD_UNIT = 'days'
const SAPI_QUERY_PERIOD_VALUE = 7

export async function search({ term, fulfillmentLocationId }) {
  let startTime, endTime
  startTime = performance.now()
  const webShellConfigurations = await fetchWebShellConfigurations()
  const isBarcodeSearch = webShellConfigurations?.find(
    (item) => item.key === WEBSHELL_BARCODE_SEARCH_KEY
  )

  if (isBarcodeSearch?.value === 'true') {
    const { shipments } = await getShipmentsFromBarcodeQuery(
      term,
      fulfillmentLocationId
    )

    handleSearchErrors(shipments)

    return shipments[0]
  }

  const shipments = uniqBy(
    Array.prototype.concat(
      ...(await Promise.race([
        ...['packageRef', 'barcode', 'shipmentRef'].map(
          (as) =>
            new Promise((resolve) =>
              authenticationProxy
                .makeAuthenticatedRequest({
                  url: `${SAPI_BASEURL}/v3/shipments`,
                  params: {
                    locationKey: fulfillmentLocationId,
                    [as]: term,
                    start: SAPI_QUERY_START_VALUE,
                    limit: SAPI_QUERY_LIMIT_VALUE,
                    since: moment()
                      .subtract(SAPI_QUERY_PERIOD_VALUE, SAPI_QUERY_PERIOD_UNIT)
                      .toISOString()
                  },
                  cache: false
                })
                .then(({ shipments }) => shipments.length && resolve(shipments))
            )
        ),
        new Promise((resolve) => setTimeout(() => resolve([]), 3000))
      ]))
    ),
    ({ id }) => id
  )

  endTime = performance.now()
  const elapsedTimeInMs = endTime - startTime
  logPackageScan(term, CODE_LOCATIONS.SAPI_PKG_SEARCH, {
    message: 'SAPI search completed.',
    elapsedTimeInMs
  })

  handleSearchErrors(shipments)

  return shipments[0]
}

export async function fetchWebShellConfigurations() {
  const { moreConfigurations } = store.getState().webShell.config

  return moreConfigurations
}

function handleSearchErrors(shipments) {
  if (shipments.length === 0) {
    throw new Error(`Shipment not found, please scan another barcode`)
  }
  if (shipments.length > 1) {
    throw new Error(`Multiple shipments found, please scan another barcode`)
  }
  if (shipments[0].status === 'cancelled') {
    throw new Error(`Shipment was cancelled`)
  }
}

export async function getShipmentsFromBarcodeQuery(
  term,
  fulfillmentLocationId
) {
  return authenticationProxy.makeAuthenticatedRequest({
    url: `${SAPI_BASEURL}/v3/shipments`,
    params: {
      locationKey: fulfillmentLocationId,
      barcode: term,
      start: SAPI_QUERY_START_VALUE,
      limit: SAPI_QUERY_LIMIT_VALUE,
      since: moment()
        .subtract(SAPI_QUERY_PERIOD_VALUE, SAPI_QUERY_PERIOD_UNIT)
        .toISOString()
    }
  })
}

export async function getPalletBox(palletBoxId, location) {
  const result = await authenticationProxy.makeAuthenticatedRequest({
    url: `${SAPI_BASEURL}/v3/pallet-boxes`,
    method: 'GET',
    params: {
      locationKey: location,
      externalId: palletBoxId
    },
    cache: false
  })
  const {
    _embedded: { pallet_boxes: palletBoxes }
  } = result
  if (palletBoxes && palletBoxes.length === 1) {
    return palletBoxes[0]
  } else {
    return null
  }
}

/**
 * removes given pallet box from specified truck
 * @param {string} truckId internal SAPI truck Id
 * @param {string} palletBoxId internal SAPI pallet box Id
 * @param {string} location logistics location id for location
 */
export async function removePalletBoxFromTruck(truckId, palletBoxId, location) {
  await authenticationProxy.makeAuthenticatedRequest({
    url: `${SAPI_BASEURL}/v3/trucks/${truckId}:removePalletBox`,
    method: 'POST',
    params: {
      locationKey: location
    },
    data: {
      palletBoxId: palletBoxId
    }
  })
}

/**
 * Adds given pallet boxes to specified truck and returns count of pallet boxes in truck
 * @param {string} truckReference Truck Barcode/Reference
 * @param {Array} palletBoxIds Array of pallet box IDs
 * @returns {number} count Count of pallet boxes in truck after addition
 */
export async function addPalletBoxesToTruck(
  truckReference,
  palletBoxIds,
  location
) {
  const palletBoxes = palletBoxIds.map((palletBoxId) => {
    return {
      externalId: palletBoxId
    }
  })
  const truck = await getTruckByReference(truckReference, location)

  await authenticationProxy.makeAuthenticatedRequest({
    url: `${SAPI_BASEURL}/v3/trucks/${truck.id}:addPalletBoxes`,
    method: 'POST',
    params: {
      locationKey: location
    },
    data: {
      palletBoxRefs: palletBoxes
    }
  })

  let sortManPalletBoxesInTruck = []
  if (truck.palletBoxes && truck.palletBoxes.length) {
    sortManPalletBoxesInTruck = truck.palletBoxes.filter(
      (palletBox) =>
        palletBox.createdBy === 'SortMan' ||
        palletBox.createdBy === 'Sorted shipment event'
    )
  }

  return sortManPalletBoxesInTruck.length + palletBoxes.length
}

/**
 * Gets truck by truck reference.
 * Reference here is equivalent to Barcode assigned to truck.
 * @param {*} truckReference Barcode/Reference of truck
 * @returns {Promise<Truck>} truck
 */
async function getTruckByReference(truckReference, location) {
  const {
    _embedded: { trucks }
  } = await authenticationProxy.makeAuthenticatedRequest({
    url: `${SAPI_BASEURL}/v3/trucks`,
    method: 'GET',
    params: {
      locationKey: location,
      barcode: truckReference
    },
    cache: false
  })

  if (!trucks || !trucks.length) {
    throw new Error(
      `No Truck found, reference ${truckReference} could not be associated with any truck`
    )
  }

  if (trucks.length > 1) {
    throw new Error(`Multiple trucks found for reference ${truckReference}`)
  }

  return trucks[0]
}

/**
 * Gets truck by truck reference.
 * Reference here is equivalent to Barcode assigned to truck.
 * @param {*} truckReference Barcode/Reference of truck
 * @returns {Promise<Truck>} truck
 */
export async function getTruckById(truckId, location) {
  const truck = await authenticationProxy.makeAuthenticatedRequest({
    url: `${SAPI_BASEURL}/v3/trucks/${truckId}`,
    method: 'GET',
    params: {
      locationKey: location
    },
    cache: false
  })

  return truck
}

export async function moveShipments(
  fromSapiPalletBoxId,
  toSapiPalletBoxId,
  deleteFromPalletBox,
  locationId
) {
  return authenticationProxy.makeAuthenticatedRequest({
    url: `${SAPI_BASEURL}/v3/pallet-boxes/${toSapiPalletBoxId}:takeShipmentsFromPalletBox?locationKey=${locationId}`,
    method: 'POST',
    data: {
      fromPalletBoxId: fromSapiPalletBoxId,
      deleteFromPalletBox
    }
  })
}
