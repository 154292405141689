import errorSound from './errorSound.mp3'
import pkgScanSound from './pkg-scan-sound.wav'
import successSound from './successSound.mp3'

export function playAudio(type) {
  switch (type) {
    case 'error':
      let errorAudio = new Audio(errorSound)
      errorAudio.play()
      break
    case 'success':
      let successAudio = new Audio(successSound)
      successAudio.play()
      break
    case 'success_pkg_scan':
      let successPkgAudio = new Audio(pkgScanSound)
      successPkgAudio.play()
      break
    case 'success_pb_scan':
      let successPbAudio = new Audio(successSound)
      successPbAudio.play()
      break
    default:
      return
  }
}
