import { setupCache, buildMemoryStorage } from 'axios-cache-interceptor'
import Axios from 'axios'

const CACHE_TTL = 60 * 1000 * 60 // 60 minutes
const CLONE_DATA = false
const CLEANUP_INTERVAL = false
const MAX_ENTRIES = 1000

const axiosInstance = Axios.create()

const cacheOptions = {
  storage: buildMemoryStorage(CLONE_DATA, CLEANUP_INTERVAL, MAX_ENTRIES),
  interpretHeader: false,
  ttl: CACHE_TTL,
  cacheTakeover: false
}
const axios = setupCache(axiosInstance, cacheOptions)

export function getCachedAxiosInstance() {
  return axios
}
