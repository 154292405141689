const CODE_LOCATIONS = Object.freeze({
  PKG_SCAN: 'PACKAGE_SCAN',
  SAPI_PKG_SEARCH: 'SAPI_PACKAGE_SEARCH',
  PALLET_SCAN: 'PALLET_SCAN',
  PALLET_SCAN_TO_TRUCK: 'PALLET_SCAN_TO_TRUCK',
  LOAD_PALLET_INTO_TRUCK: 'LOAD_PALLET_INTO_TRUCK',
  REMOVE_PALLET_FROM_TRUCK: 'REMOVE_PALLET_FROM_TRUCK'
})

export default CODE_LOCATIONS
