import React, { Component } from 'react'
import TextField from '@cimpress/react-components/lib/TextField'
import autobind from 'react-autobind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { addPalletBoxesToTruck } from '../authentication/sapi'
import { dispatchMessage } from '../redux/notifications/actions'
import CODE_LOCATIONS from '../logging/codeLocation'
import { logTruckInfo } from '../logging/logger'
import time from '../logging/time'

export class Truck extends Component {
  constructor(props) {
    super(props)
    this.state = { truckReference: '' }
    autobind(this)
  }

  onScanTruckChange({ target: { value } }) {
    this.setState({ truckReference: value.trim() })
  }

  onKey({ key }) {
    if (key !== 'Enter') return
    if (!this.state.truckReference) return
    dispatchMessage(null, null)
    this.loadPalletBoxIntoTruck()
  }

  async loadPalletBoxIntoTruck() {
    let startTime, elapsedTimeInMs
    startTime = performance.now()
    try {
      let count = await addPalletBoxesToTruck(
        this.state.truckReference,
        [this.props.palletBoxId],
        this.props.currentLocation.id
      )
      this.props.onPalletBoxLoad({
        message: `Pallet box successfully loaded into truck.  Truck now contains ${count} pallet box${
          count > 1 ? 'es' : ''
        }.`,
        step: 'scan-pallet-box',
        palletBoxId: '',
        titleText: 'Scan Pallet Box to Truck'
      })
      elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
      logTruckInfo(
        this.state.truckReference,
        this.props.palletBoxId,
        CODE_LOCATIONS.LOAD_PALLET_INTO_TRUCK,
        {
          message: `Pallet box successfully loaded into truck.  Truck now contains ${count} pallet box`,
          elapsedTimeInMs
        }
      )
    } catch (error) {
      this.props.onError({
        err: error,
        step: 'scan-pallet-box',
        titleText: 'Scan Pallet Box to Truck'
      })
      elapsedTimeInMs = time.getElapsedTimeInMs(startTime)
      logTruckInfo(
        this.state.truckReference,
        this.props.palletBoxId,
        CODE_LOCATIONS.LOAD_PALLET_INTO_TRUCK,
        {
          error: `Encountered an error while loading pallet box into truck: ${error}`,
          elapsedTimeInMs
        }
      )
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <TextField
              id="text-field"
              name="truck"
              label="Please scan truck"
              value={this.state.truckReference}
              onChange={this.onScanTruckChange}
              onKeyPress={this.onKey}
              autoFocus
            />
          </div>
        </div>
      </div>
    )
  }
}

Truck.propTypes = {
  palletBoxId: PropTypes.string,
  onPalletBoxLoad: PropTypes.func,
  onError: PropTypes.func,
  currentLocation: PropTypes.object
}

export default connect((state, ownProps) => {
  return {
    currentLocation: state.app.currentLocation,
    onPalletBoxLoad: ownProps.onPalletBoxLoad,
    onError: ownProps.onError,
    palletBoxId: ownProps.palletBoxId
  }
})(Truck)
