import { makeAuthenticatedRequest } from './authenticationProxy'
import appConfig from '../app-config.json'
import moment from 'moment'

const SES_BASEURL = appConfig.SES_BASEURL

export async function scanEvent({ packageId, logisticsLocationId, user }) {
  await makeAuthenticatedRequest({
    url: `${SES_BASEURL}/api/v1/events`,
    method: 'POST',
    data: {
      source: 'Sorting Console',
      logisticsLocationId,
      user,
      packageId,
      eventType: 'PACKAGE-SCANNED',
      eventDateTime: moment.utc().format()
    }
  })
}

export async function palletScanningEvent({
  user,
  palletBoxId,
  logisticsLocationId
}) {
  await makeAuthenticatedRequest({
    url: `${SES_BASEURL}/api/v1/events`,
    method: 'POST',
    data: {
      source: 'Sorting Console',
      user,
      palletBoxId,
      logisticsLocationId,
      eventType: 'PALLET-BOX-SCANNED',
      eventDateTime: moment.utc().format()
    }
  })
}
