import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import PropTypes from 'prop-types'
import { Select } from '@cimpress/react-components'
import { Navigate } from 'react-router-dom'

import { dispatchCurrentLocation } from './redux/app/actions'
import { checkLocation } from './authentication/logisticsLocation'

export class SelectLocation extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  async handleChange(event) {
    let checkedLocation = await checkLocation(event.value)
    if (checkedLocation) {
      dispatchCurrentLocation(checkedLocation)
    }
  }

  async componentDidMount() {
    if (this.props.locations && this.props.locations.length === 1) {
      const result = await checkLocation(this.props.locations[0])
      dispatchCurrentLocation(result)
    }
  }

  formatLocations(locations) {
    return (
      locations &&
      locations.map((location) => {
        return { value: location, label: location.name }
      })
    )
  }

  render() {
    let verificationDisplay = this.props.verification()

    if (verificationDisplay) {
      return verificationDisplay
    }

    if (this.props.currentLocation) {
      return <Navigate to={this.props.sortPackagePath} />
    }

    return (
      <div>
        <div className="container h4">Select Location</div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Select
                placeholder="Select logistics location..."
                options={this.formatLocations(this.props.locations)}
                onChange={this.handleChange}
                clearable={false}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SelectLocation.propTypes = {
  locations: PropTypes.array,
  currentLocation: PropTypes.object,
  sortPackagePath: PropTypes.string,
  verification: PropTypes.func
}

export default connect((state, ownProps) => {
  return {
    locations: state.app.locations,
    currentLocation: state.app.currentLocation,
    sortPackagePath: state.app.paths.sortPackage,
    verification: ownProps.verification
  }
})(SelectLocation)
