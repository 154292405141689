import React, { Component } from 'react'
import Button from '@cimpress/react-components/lib/Button'
import autobind from 'react-autobind'
import PropTypes from 'prop-types'
import { playAudio } from '../audio/audio'

export class ConfirmMove extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  componentDidMount() {
    playAudio('information')
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>
              The package, {this.props.package}, has already been sorted. Would
              you like to move the package or scan a new package?
            </p>
            <div className="col-md-4">
              <Button
                name="denyButton"
                type="default"
                size="default"
                onClick={this.props.onDeny}
              >
                Scan a New Package
              </Button>
            </div>
            <div className="col-md-4">
              <Button
                name="confirmButton"
                type="default"
                size="default"
                onClick={this.props.onConfirm}
              >
                Move Package
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ConfirmMove.propTypes = {
  package: PropTypes.string,
  onConfirm: PropTypes.func,
  onDeny: PropTypes.func
}
