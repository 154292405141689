import React from 'react'
import { Route, Navigate, Routes, NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import SortPackageApp from './SortPackage/SortPackageApp'
import TruckLoaderApp from './TruckLoader/TruckLoaderApp'
import MovePackagesApp from './MovePackages/MovePackagesApp'
import SelectLocation from './SelectLocation'
import { Header } from '@cimpress-technology/react-platform-header/lib/Header'
import Spinner from '@cimpress/react-components/lib/shapes/Spinner'
import { Snackbar } from '@cimpress/react-components'
import {
  getApplicationConfiguration,
  AuthCacher
} from '@cimpress-technology/web-shell-communication'

import { allowedLocations } from './authentication/coamProxy'
import {
  dispatchApp,
  dispatchAllow,
  dispatchCurrentLocation
} from './redux/app/actions'
import { dispatchError, dispatchMessage } from './redux/notifications/actions'
import * as auth from './authentication/auth'
import autobind from 'react-autobind'
import { checkLocation } from './authentication/logisticsLocation'
import { playAudio } from './audio/audio'

export class App extends React.Component {
  constructor(props) {
    super(props)
    autobind(this)
    dispatchApp({ allow: false, wait: true })
    this.handleAuthConfig()
  }

  buildHeaderLinks() {
    const paths = this.props.app.paths
    const activePath = this.props.location.pathname

    let appLinks = [
      {
        id: 'sort-package',
        content: <Link to={paths.sortPackage}>Sort Package</Link>,
        isActive: activePath === paths.sortPackage
      },
      {
        id: 'load-truck',
        content: <Link to={paths.loadTruck}>Load Truck</Link>,
        isActive: activePath === paths.loadTruck
      },
      {
        id: 'move-packages',
        content: <Link to={paths.movePackages}>Move Packages</Link>,
        isActive: activePath === paths.movePackages
      }
    ]

    return appLinks
  }

  async getAllowedLocations() {
    const locations = await allowedLocations()
    let currentLocation
    let allow = locations.length > 0
    if (locations.length === 1) {
      currentLocation = await checkLocation(locations[0])
    } else if (this.props.attemptedLocation) {
      let foundLocation = locations.find(
        (location) => location.id === this.props.attemptedLocation
      )
      if (foundLocation) {
        currentLocation = await checkLocation(foundLocation)
      } else {
        this.props.history.push('/select-location')
        dispatchError(
          `You don't have access to logistics location ${this.props.attemptedLocation}.`,
          'error'
        )
      }
    }
    dispatchApp({ locations, currentLocation, allow, wait: false })
  }

  async handleAuthConfig() {
    const authConfig = await getApplicationConfiguration()
    auth.setWebShellAppConfig(authConfig)
    if (authConfig) {
      let authCacher = await AuthCacher.getAuthCacher()
      auth.setWebShellAuthConfig(authCacher.getAuthInfo())
    }
    await this.getAllowedLocations()
  }

  async componentDidMount() {
    await auth.logIn()
    this.getAllowedLocations()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.playAudio !== this.props.playAudio && this.props.playAudio) {
      playAudio(this.props.audioType)
    }
  }

  async handleLoginClick() {
    dispatchAllow(false)
    await auth.logIn()
  }

  handleLogoutClick() {
    dispatchAllow(false)
    auth.logOut()
  }

  verification() {
    const { allow, wait } = this.props.app
    const isAuthenticated = this.props.isAuthenticated

    if (wait) return <Spinner size={'large'} className="text-center" />
    if (!isAuthenticated)
      return <Spinner size={'large'} className="text-center" />
    if (!allow && isAuthenticated)
      return (
        <div>
          You are not authorized to use the Sorting Console. Please contact{' '}
          <a href="mailto:ShipSorterSupport@cimpress.com">
            ShipSorterSupport@cimpress.com
          </a>{' '}
          for assistance.
        </div>
      )
    return
  }

  handleHideSnackbar(type) {
    if (type === 'error') {
      dispatchError(null, null)
    } else if (type === 'success') {
      dispatchMessage(null, null)
    }
    const textField = document.querySelector('#text-field')
    if (textField) {
      textField.focus()
    }
  }

  render() {
    const paths = this.props.app.paths

    return (
      <div>
        <Header
          responsive
          appTitle={
            <NavLink
              to="/select-location"
              onClick={() => {
                dispatchCurrentLocation(null)
              }}
              style={{ 'white-space': 'nowrap' }}
            >
              Sorting Console{' '}
              {this.props.app.currentLocation
                ? `- ${this.props.app.currentLocation.name}`
                : ''}
            </NavLink>
          }
          appLinks={
            this.props.app.currentLocation ? this.buildHeaderLinks() : []
          }
          profile={this.props.profile}
          isLoggedIn={this.props.isAuthenticated}
          onLogInClicked={this.handleLoginClick}
          onLogOutClicked={this.handleLogoutClick}
        />
        <Snackbar
          delay="5000"
          onHideSnackbar={() => this.handleHideSnackbar('success')}
          bsStyle="success"
          show={!!this.props.notifications.message}
        >
          {this.props.notifications.message}
        </Snackbar>
        <Snackbar
          onHideSnackbar={() => this.handleHideSnackbar('error')}
          bsStyle="danger"
          show={!!this.props.notifications.error}
        >
          {this.props.notifications.error}
        </Snackbar>
        <Routes>
          <Route
            strict
            path={paths.sortPackage}
            element={<SortPackageApp verification={this.verification} />}
          />
          <Route
            strict
            path={paths.loadTruck}
            element={<TruckLoaderApp verification={this.verification} />}
          />
          <Route
            strict
            path={paths.movePackages}
            element={<MovePackagesApp verification={this.verification} />}
          />
          <Route
            exact
            path="/select-location"
            element={<SelectLocation verification={this.verification} />}
          />
          <Route path="/" element={<Navigate to={paths.redirectPath} />} />
        </Routes>
      </div>
    )
  }
}

export default connect((state, ownProps) => {
  let pathName = ownProps.location.pathname
  let index = pathName.indexOf('/', 1)
  let attemptedLocation
  if (index > 1) {
    attemptedLocation = pathName.substr(index + 1)
  }

  return {
    isAuthenticated:
      state.webShell.isAuthenticated || state.auth.isAuthenticated,
    profile: state.webShell.isAuthenticated
      ? state.webShell.profile.userProfile
      : state.auth.profile,
    notifications: state.notifications,
    app: state.app,
    attemptedLocation,
    playAudio: state.notifications.error || state.notifications.message,
    audioType: state.notifications.audioType
  }
})(App)
