import store from '../index'

export const dispatchAllow = (allow) => {
  store.dispatch({ type: 'DISPATCH_ALLOW', allow })
}

export const dispatchWait = (wait) => {
  store.dispatch({ type: 'DISPATCH_WAIT', wait })
}

export const dispatchCurrentLocation = (currentLocation) => {
  store.dispatch({ type: 'DISPATCH_CURRENT_LOCATION', currentLocation })
}
export const dispatchApp = ({ allow, wait, locations, currentLocation }) => {
  store.dispatch({ type: 'DISPATCH_LOCATIONS', locations })
  store.dispatch({ type: 'DISPATCH_CURRENT_LOCATION', currentLocation })
  store.dispatch({ type: 'DISPATCH_ALLOW', allow })
  store.dispatch({ type: 'DISPATCH_WAIT', wait })
}
