const getElapsedTimeInMs = (startTime) => {
  const endTime = performance.now()
  const elapsedTime = (endTime - startTime).toFixed(2)

  return elapsedTime
}

module.exports = {
  getElapsedTimeInMs
}
