import React from 'react'
import { makeAuthenticatedRequest } from './authenticationProxy'
import appConfig from '../app-config.json'
import { dispatchError } from '../redux/notifications/actions'

const LOGISTICS_LOCATION_BASEURL = appConfig.LOGISTICS_LOCATION_BASEURL

const FULFILLMENT_LOCATION_MAPPING = {
  windsor: 'bne16129w2w',
  venlo: 'b7bhkn35wt0',
  'windsor-demo': 'bne16129w2w',
  'venlo-demo': 'b7bhkn35wt0',
  '1eg02P-FxY': 'wttqxyap',
  ktdKIZ78Po: 'wttqxyap',
  'Xl-swig6jz': 'xa3fawmu'
}

export async function getLocations() {
  let url = `${LOGISTICS_LOCATION_BASEURL}/v0/locations?excludes=countryCalendars`
  let locations = []
  let page
  do {
    page = await makeAuthenticatedRequest({ url })
    locations = locations.concat(
      page.items.map((location) => {
        return formatLocation(location)
      })
    )
  } while (
    page._links &&
    page._links.next &&
    page._links.next.href &&
    (url = page._links.next.href)
  )

  return locations
}

export async function getLocation(logisticsLocationId) {
  let url = `${LOGISTICS_LOCATION_BASEURL}/v0/locations/${logisticsLocationId}`
  try {
    let location = await makeAuthenticatedRequest({ url })
    return formatLocation(location)
  } catch (err) {
    if (err.status === 404) {
      return null
    } else {
      throw err
    }
  }
}

export async function getLocationConfiguration(location, keyword) {
  let url = `${LOGISTICS_LOCATION_BASEURL}/v0/locations/${location.id}/customData/${keyword}`
  try {
    return await makeAuthenticatedRequest({ url })
  } catch (err) {
    const { status } = err || {}

    if (status === 404) return {} // default configuration here
    throw new Error(`Error getting ${keyword} data for ${location.id}: ${err}`)
  }
}

export async function checkLocation(location) {
  if (location.fulfillmentLocationIds) {
    if (location.fulfillmentLocationIds.length === 1) {
      let sortation = await getLocationConfiguration(location, 'sortation')
      return {
        id: location.id,
        name: location.name,
        fulfillmentLocationId: location.fulfillmentLocationIds[0],
        settings: sortation.sortingConsole || {}
      }
    } else if (location.fulfillmentLocationIds.length > 1) {
      dispatchError(
        <div>
          Multiple fulfillment locations configured for logistics location:{' '}
          {location.id}. Contact{' '}
          <a href="mailto:ShipSorterSupport@cimpress.com">
            ShipSorterSupport@cimpress.com
          </a>{' '}
          for assistance
        </div>,
        'error'
      )
    }
  } else if (
    !location.fulfillmentLocationIds ||
    location.fulfillmentLocationIds.length < 1
  ) {
    dispatchError(
      `No fulfillment locations configured for logistics location: ${location.id}`,
      'error'
    )
  }
}

function formatLocation(location) {
  if (FULFILLMENT_LOCATION_MAPPING[location.id]) {
    return {
      id: location.id,
      name: location.name,
      fulfillmentLocationIds: [FULFILLMENT_LOCATION_MAPPING[location.id]]
    }
  } else {
    return {
      id: location.id,
      name: location.name,
      fulfillmentLocationIds: location.fulfillmentLocations.map(
        (fulfillmentLocation) => {
          return fulfillmentLocation.id
        }
      )
    }
  }
}
