import store from '../redux/index'

export function logIn() {
  let webShell = store.getState().webShell
  if (webShell.isAuthenticated) {
    return Promise.resolve(true)
  }
  let auth0 = store.getState().auth.auth0
  if (!auth0.isLoggedIn()) {
    return auth0.ensureAuthentication().then(function (success) {
      if (success) {
        let profile = getProfile()
        store.dispatch({ type: 'LOG_IN', profile })
        return success
      } else {
        store.dispatch(allLogOut())
      }
    })
  } else {
    return Promise.resolve(true)
  }
}

function allLogOut() {
  return function (dispatch) {
    dispatch({ type: 'LOG_OUT' })
    dispatch({ type: 'WEBSHELL_LOG_OUT' })
  }
}

export function logOut() {
  let auth0 = store.getState().auth.auth0
  store.dispatch(allLogOut())
  return auth0.logout('/', true)
}

export function isLoggedIn() {
  let webShell = store.getState().webShell
  if (webShell.isAuthenticated) {
    return true
  }
  let auth0 = store.getState().auth.auth0
  return auth0.isLoggedIn()
}

export function getProfile() {
  let webShell = store.getState().webShell
  if (webShell.isAuthenticated) {
    return webShell.profile.userProfile
  }
  let auth0 = store.getState().auth.auth0
  return auth0.getProfile()
}

export function getAccessToken() {
  let webShell = store.getState().webShell
  if (webShell.isAuthenticated) {
    return webShell.profile.accessToken
  }
  let auth0 = store.getState().auth.auth0
  return auth0.getAccessToken()
}

export function setWebShellAuthConfig(authConfig) {
  if (!authConfig) {
    return
  }
  let profile = {}
  profile.accessToken = authConfig.accessToken
  profile.idToken = authConfig.idToken
  profile.expiresAt = authConfig.expiresAt
  profile.userProfile = authConfig.userProfile
  store.dispatch({ type: 'WEBSHELL_LOG_IN', profile })
}

export function setWebShellAppConfig(appConfig) {
  if (!appConfig) {
    return
  }
  let config = {}
  config.station = appConfig.stationName
  config.fulfillerNbr = appConfig.fulfillerNbr
  config.moreConfigurations = appConfig.configurations
  store.dispatch({ type: 'WEBSHELL_APP_CONFIG', config })
}
