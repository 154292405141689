import { makeAuthenticatedRequest } from '../authentication/authenticationProxy'
import appConfig from '../app-config.json'
import { defaults } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const { RIVER_BASE_URL, RIVER_STREAM_ID } = appConfig

export async function sendToRiver(drop) {
  const dropId = uuidv4()
  const timestamp = new Date().toISOString()
  await makeAuthenticatedRequest({
    url: `${RIVER_BASE_URL}/v2/streams/${RIVER_STREAM_ID}/waterdrops`,
    method: 'POST',
    data: defaults(drop, { dropId, timestamp })
  })
}
